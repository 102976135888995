.card {
  @apply container p-3 bg-gray-200 rounded-md mt-8 mb-8 drop-shadow;
}

.icon {
  @apply w-5 inline-block mr-2;
}

.tag {
  @apply mb-2 rounded-full p-3 pt-1 pb-1 text-white shadow mr-1 prose prose-sm;
}

.details {
  & > * {
    padding: 20px 0;
  }

  & > *:first-child {
    padding-top: 0;
  }

  & > *:last-child {
    padding-bottom: 0;
  }
}
